@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000300;
}

.h-18 {
  height: 4.5rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.translate-x--32 {
  transform: translateX(-8rem);
}

/*Named this calibri because it is very close resemblance of Calibri font*/
.calibri-300 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

/*Named this calibri because it is very close resemblance of Calibri font*/
.calibri-400 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

/*Named this calibri because it is very close resemblance of Calibri font*/
.calibri-500 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

/*Named this calibri because it is very close resemblance of Calibri font*/
.calibri-600 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

/*Named this calibri because it is very close resemblance of Calibri font*/
.calibri-700 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

/*Named this calibri because it is very close resemblance of Calibri font*/
.calibri-800 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.avenir-400 {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.avenir-500 {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.avenir-600 {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.avenir-700 {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.avenir-800 {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.avenir-900 {
  font-family: "Sen", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.marquee {
  display: flex;
  width: 300%;
  animation: marquee 25s linear infinite;
}

.marquee-content {
  display: flex;
  flex-wrap: nowrap;
}

@keyframes marquee {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-50%);
  }
}

#bg-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.h-18 {height: 4.5rem;}

.h-22 {height: 5.5rem;}

.pt-18 {padding-top: 4.5rem;}

.vertical-line {
  height: 20rem;
  width: 1px;
  background-color: #7ab1cb; /* or any color you prefer */
}

.about-us-contact-gradient-background {
  background-image: linear-gradient(to right, rgb(8, 29, 43, 1), rgb(22, 63, 108,0.8),rgb(117, 141, 167, 0.5)), url("./assets/AboutUs/AboutUsContactBackground.jpg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-us-location-gradient-background {
  background-image: linear-gradient(to right, rgb(8, 29, 43, 1), rgb(22, 63, 108,0.8),rgb(117, 141, 167, 0.5)), url("./assets/ContactUs/ContactUsLocationsBackground.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
